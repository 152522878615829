<template>

  <div class="flex flex-col items-center justify-center w-full min-h-screen">
    <div class="flex flex-col max-w-screen-lg bg-white p-8 rounded-lg m-4">

      <div class="text-2xl font-bold text-center uppercase">
        PRIVACY NOTICE
      </div>

      <div class="uppercase text-sm font bold">
        Last updated March 26, 2021
      </div>

      <div class="mt-4">
        Thank you for choosing to be part of our community at Black Flame Digital LTD ("Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at: <a class="underline" href="mailto:support@blackflamedigital.com">support@blackflamedigital.com</a> or use our live chat which can be found on bottom right section of the Site.
      </div>

      <div class="mt-2">
        When you visit our website https://invoicelabs.io (the "Website"), and more generally, use any of our services (the "Services", which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
      </div>

      <div class="mt-2">
        This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as, any related services, sales, marketing or events.
      </div>

      <div class="mt-2 font-bold">
        Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
      </div>

      <div class="font-bold mt-8">
        1. WHAT INFORMATION DO WE COLLECT?
      </div>
      <div class="mt-2 font-bold">
        Personal information you disclose to us
      </div>
      <div class="mt-2">
        <span class="underline">In Short</span>: We collect personal information that you provide to us.
      </div>
      <div class="mt-2">
        We collect personal information that you voluntarily provide to us when you register on the Website, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Website or otherwise when you contact us.
      </div>
      <div class="mt-2">
        The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make and the products and features you use. The personal information we collect may include the following:
      </div>
      <div class="ml-4 mt-2">
        <span class="font-bold">Personal Information Provided by You.</span> We collect names; email addresses; passwords; and other similar information.
      </div>
      <div class="ml-4 mt-2">
        <span class="font-bold">Payment Data.</span> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. <br />
        You may find their privacy notice link(s) here: <a href="https://stripe.com/en-gb/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/en-gb/privacy</a>.
      </div>
      <div class="ml-4 mt-2">
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
      </div>

      <div class="font-bold mt-8">
        Information automatically collected
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Website.
      </div>
      <div class="mt-2">
        We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.
      </div>
      <div class="mt-2">
        Like many businesses, we also collect information through cookies and similar technologies.
      </div>
      <div class="mt-2">
        The information we collect includes:
      </div>
      <div class="mt-2 ml-4">
        <span class="underline">Log and Usage Data</span>. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
      </div>
      <div class="mt-2 ml-4">
        <span class="underline">Device Data</span>. We collect device data such as information about your computer, phone, tablet or other device you use to access the Website. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.
      </div>
      <div class="mt-2 ml-4">
        <span class="underline">Location Data</span>. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.
      </div>

      <div class="font-bold mt-8">
        2. HOW DO WE USE YOUR INFORMATION?
      </div>
      <div class="mt-2">
        <span class="underline">In Short</span>:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
      </div>
      <div class="mt-2">
        We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
      </div>
      <div class="mt-2">
        We use the information we collect or receive:
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To facilitate account creation and logon process.</span> If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To post testimonials.</span> We post testimonials on our Website that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at support@blackflamedigital.com and be sure to include your name, testimonial location, and contact information.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Request feedback.</span> We may use your information to request feedback and to contact you about your use of our Website.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To enable user-to-user communications.</span> We may use your information in order to enable user-to-user communications with each user's consent.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To manage user accounts.</span> We may use your information for the purposes of managing our account and keeping it in working order.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To send administrative information to you.</span> We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To protect our Services.</span> We may use your information as part of our efforts to keep our Website safe and secure (for example, for fraud monitoring and prevention).
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</span>
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">To respond to legal requests and prevent harm.</span> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
      </div>

      <div class="font-bold mt-8">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
      </div>
      <div class="mt-2">
        We may process or share your data that we hold based on the following legal basis:
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Consent:</span> We may process your data if you have given us specific consent to use your personal information for a specific purpose.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Legitimate Interests:</span> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Performance of a Contract:</span> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Legal Obligations:</span> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Vital Interests:</span> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
      </div>
      <div class="mt-2">
        More specifically, we may need to process your data or share your personal information in the following situations:
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Vendors, Consultants and Other Third-Party Service Providers.</span> We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Website, which will enable them to collect data on our behalf about how you interact with our Website over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
      </div>
      <div class="mt-2 ml-4">
        <span class="font-bold">Third-Party Advertisers.</span> We may use third-party advertising companies to serve ads when you visit or use the Website. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.
      </div>

      <div class="font-bold mt-8">
        4. WHO WILL YOUR INFORMATION BE SHARED WITH?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We only share information with the following third parties.
      </div>
      <div class="mt-2">
        We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
      </div>
      <div class="mt-2 ml-4 font-bold">
        Advertising, Direct Marketing, and Lead Generation
      </div>
      <div class="mt-2 ml-4">
        Google AdSense
      </div>
      <div class="mt-2 ml-4 font-bold">
        Content Optimization
      </div>
      <div class="mt-2 ml-4">
        Google Site Search
      </div>
      <div class="mt-2 ml-4 font-bold">
        Invoice and Billing
      </div>
      <div class="mt-2 ml-4">
        Stripe
      </div>
      <div class="mt-2 ml-4 font-bold">
        Web and Mobile Analytics
      </div>
      <div class="mt-2 ml-4">
        Google Analytics
      </div>

      <div class="font-bold mt-8">
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We may use cookies and other tracking technologies to collect and store your information.
      </div>
      <div class="mt-2">
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
      </div>

      <div class="font-bold mt-8">
        6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our Website.
      </div>
      <div class="mt-2">
        The Website may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the Website. You should review the policies of such third parties and contact them directly to respond to your questions.
      </div>

      <div class="font-bold mt-8">
        7. HOW LONG DO WE KEEP YOUR INFORMATION?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
      </div>
      <div class="mt-2">
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
      </div>
      <div class="mt-2">
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </div>

      <div class="font-bold mt-8">
        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We aim to protect your personal information through a system of organizational and technical security measures.
      </div>
      <div class="mt-2">
        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.
      </div>

      <div class="font-bold mt-8">
        9. DO WE COLLECT INFORMATION FROM MINORS?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> We do not knowingly collect data from or market to children under 18 years of age.
      </div>
      <div class="mt-2">
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support@blackflamedigital.com.
      </div>

      <div class="font-bold mt-8">
        10. WHAT ARE YOUR PRIVACY RIGHTS?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
      </div>
      <div class="mt-2">
        In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
      </div>
      <div class="mt-2">
        If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
      </div>
      <div class="mt-2">
        If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
      </div>
      <div class="mt-2">
        If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
      </div>
      <div class="mt-2">
        If you have questions or comments about your privacy rights, you may email us at support@blackflamedigital.com.
      </div>

      <div class="mt-4 font-bold">
        Account Information
      </div>
      <div class="mt-2">
        If you would at any time like to review or change the information in your account or terminate your account, you can:
      </div>
      <div class="mt-2 ml-4">
        Log in to your account settings and update your user account.
      </div>
      <div class="mt-2 ml-4">
        Contact us using the contact information provided.
      </div>
      <div class="mt-2">
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
      </div>
      <div class="mt-2">
        <span class="font-bold">Cookies and similar technologies</span>: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website. To opt-out of interest-based advertising by advertisers on our Website visit http://www.aboutads.info/choices/.
      </div>

      <div class="font-bold mt-8">
        11. CONTROLS FOR DO-NOT-TRACK FEATURES
      </div>
      <div class="mt-2">
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
      </div>

      <div class="font-bold mt-8">
        12. DO WE MAKE UPDATES TO THIS NOTICE?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </div>
      <div class="mt-2">
        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
      </div>

      <div class="font-bold mt-8">
        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </div>
      <div class="mt-2">
        <span class="underline">In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </div>
      <div class="mt-2">
        If you have questions or comments about this notice, you may contact us at: <a class="underline" href="mailto:support@blackflamedigital.com">support@blackflamedigital.com</a> or use our live chat which can be found on bottom right section of the Site.
      </div>

      <div class="font-bold mt-8">
        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </div>
      <div class="mt-2">
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please contact us at: <a class="underline" href="mailto:support@blackflamedigital.com">support@blackflamedigital.com</a>. We will respond to your request within 30 days.
      </div>

    </div>
  </div>
</template>















